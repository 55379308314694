<template>
  <div class="container">
    <div class="container-header mb-20">
      <a-button type="primary" class="header-edit" @click="editBasicInfo">编辑</a-button>
    </div>
    <div class="container-info">
      <a-avatar :size="100" shape="square" :src="form.systemLogoFileUrl ? form.systemLogoFileUrl : require('@/assets/logo.png')"  class="mb-10" />
      <div class="info-name mb-20">{{ form.systemName }}</div>
      <div class="info-description p-20" v-html="form.description"></div>
    </div>
    <a-modal
      title="编辑系统信息"
      :visible="visible"
      :confirm-loading="confirmLoading"
      wrapClassName="edit-system"
      :width="'50%'"
      @ok="onSubmit('ruleForm')"
      @cancel="resetForm('ruleForm')"
    >
      <a-form-model
        ref="ruleForm"
        :model="editForm"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 20 }"
      >

        <a-form-model-item label="系统logo" prop="systemLogoFileUrl">
          <a-upload
            name="file"
            accept="image"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            :custom-request="customRequest"
            class="mr-10"
          >
            <a-avatar v-if="isUpload" :loading="imgLoading" :size="100" shape="square" :src="fileData.fileUrl" />
            <a-avatar v-else :size="100" :loading="imgLoading" shape="square" :src="editForm.systemLogoFileUrl ? editForm.systemLogoFileUrl : require('@/assets/logo.png')" />
          </a-upload>
        </a-form-model-item>
        <a-form-model-item ref="enterpriseName" label="系统名称" prop="enterpriseName" :required="true">
          <a-input
            v-model="editForm.systemName"
            @blur="() => { $refs.enterpriseName.onFieldBlur() }"
          />
        </a-form-model-item>
        <a-form-model-item label="系统简介" prop="description">
          <a-input v-model="editForm.description" type="textarea" :auto-size="{ minRows: 3}" :maxLength="1024"  />
          <div class="input-num">{{ editForm.description.length || 0 }}/1024</div>
        </a-form-model-item>
        <!--<a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">-->
        <!--  <a-button type="primary" @click="onSubmit('ruleForm')">-->
        <!--    Create-->
        <!--  </a-button>-->
        <!--  <a-button style="margin-left: 10px;" @click="resetForm('ruleForm')">-->
        <!--    Reset-->
        <!--  </a-button>-->
        <!--</a-form-model-item>-->
      </a-form-model>
    </a-modal>

  </div>
</template>

<script>
import { systemInfo, systemInfoUpdate } from '@/api/enterprise'
import {uploadUserPortrait} from "@/api/user";

export default {
  name: 'BasicInfo',
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入系统名称'))
      } else {
        const name = value.trim()
        if (name === '') {
          callback(new Error('系统名称不能全为空格'))
        }
        callback()
      }
    }
    return {
      editForm: {
        description: '',
      },
      title: '',
      visible: false,
      confirmLoading: false,
      rules: {
        systemName: [{ validator: validatePass, trigger: 'blur' }],
        // description: [{ required: true, message: '请输入系统简介', trigger: 'blur' }]
      },
      typeList: {
        // 主体类型
        subjectTypeList: {
          '1': '个人',
          '2': '单位'
        },
        // 账户类型
        accountTypeList: {
          '1': '银行卡',
          '2': '支付宝',
          '3': '微信',
        }
      },
      form: {},
      imgLoading: false,
      isUpload: false,
      file: {},
      fileData: {
        fileCode: null
      }
    }
  },
  created() {
    this.getSystemInfo()
  },
  methods:{
    getSystemInfo() {
      systemInfo({ enterpriseId: '' }).then((res) => {
        if (res['code'] !== 0) {
          this.$antMessage.error(res['message'])
          return
        }
        this.form = res.data
      })
    },
    editBasicInfo() {
      this.editForm = {...this.form}
      this.title = '新增'
      this.visible = true
      this.isUpload = false
    },
    // 表单校验
    onSubmit(ruleForm) {
      this.$refs[ruleForm].validate(valid => {
        if (valid) {
          this.confirmLoading = true;
          const data = {
            systemName: this.editForm.systemName,
            description: this.editForm.description,
            systemLogoFileCode: this.fileData.fileCode
          }
          systemInfoUpdate(data).then((res) => {
            if (res['code' !== 0]) {
              this.$antMessage.error(res['message'])
              return
            }
            this.$store.dispatch('user/setSystemInfo', {
              systemLogoFileUrl: this.fileData.fileUrl || this.editForm.systemLogoFileUrl,
              systemName: this.editForm.systemName
            })
            this.visible = false;
            this.confirmLoading = false;
            this.editForm = {
              description: ''
            }
            this.fileData = {
              fileCode: null
            }
            this.getSystemInfo()
          })
        } else {
          return false;
        }
      });
    },
    // 取消校验
    resetForm(ruleForm) {
      this.$refs[ruleForm].resetFields()
      this.visible = false
      this.editForm = {
        description: ''
      }
      this.fileData = {
        fileCode: null
      }
    },
    // 上传前
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJpgOrPng) {
        this.$antMessage.error('您只能上传 JPG 或 png 文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 5
      if (!isLt2M) {
        this.$antMessage.error('上传图片不能超过5M!')
        return false
      }
      this.file = file
      this.isUpload = true
    },
    // 上传文件
    customRequest() {
      this.imgLoading = true
      const data = new FormData()
      data.append('file', this.file)
      data.append('userId', '')
      data.append('enterpriseId', '')
      uploadUserPortrait(data).then((res) => {
        this.imgLoading = false
        if (res['code'] !== 0) {
          this.$antMessage.error(['message'])
          return
        }
        this.fileData = res.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 50%;
  min-width: 920px;
  margin: auto;
  .container-header{
    text-align: right;
  }
  .container-info{
    text-align: center;
    .info-name{
      font-size: 22px;
    }
    .info-description{
      color: rgba(0,0,0,0.5);
      margin: auto 30px;
      text-align: left;
      border: 1px solid #e8e8e8;
      min-height: 400px;
      line-height: 1.5;
      font-size: 16px;
    }
  }
  .w-200 {
    width: 200px;
  }

}
/deep/ .ant-modal-wrap.edit-system{
  .ant-modal-body{
    max-height: 500px;
    overflow-y: auto;
  }
}

/deep/ .ant-form {
  .ant-form-item {
    width: 100%;

    .ant-form-item-label {
      width: 120px;

      label {
        //font-size: 18px;
        color: #000;

        span.item-label {
          width: calc(100% - 40px);
          text-align-last: justify;
          text-align: justify;
          display: inline-block;
        }

        &:after {
          width: 20px;
          display: inline-block;
          text-align: left;
        }
      }

    }

    .ant-form-item-control-wrapper {
      width: calc(100% - 120px);
      font-size: 18px;
    }

    &.moreText {
      .ant-form-item-control-wrapper {
        .ant-form-item-control {
          line-height: 1.5;
          padding-top: 8px;
          font-size: 16px;
        }
      }
    }
  }
}
.input-num{
  text-align: right;
  font-size: 10px;
  margin-top: -10px;
}
</style>
